<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      icon="mdi-bed"
      color="info"
      text
    >
      Bitte führe bis zum Notentermin den Semesterabschluss der Absenzen deiner
      Klasse
      {{
        items
          .filter((el) => el.open && !el.done)
          .map((el) => el.schoolClass.code)
          .join(",")
      }}
      durch!
    </v-alert>

    <v-card
      :class="`v-alert--text info--text rounded-t-0`"
      flat
      tile
      :loading="loading"
    >
      <v-divider />
      <v-card-actions>
        <v-btn
          dark
          color="info"
          text
          block
          small
          :to="{ name: 'AbsencesBalance' }"
          >durchführen</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "absence/balance",
      });
      if (this.items.length > 0) {
        if (this.items.some((el) => el.open && !el.done)) {
          this.$emit("loaded");
        }
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
